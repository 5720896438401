export const state = {
    formType: 'navigation',
    title: '',
    sis_lista_valor: [],
}
export const getters = {
    formType: state => state.formType,
    getValueListById: state => listId => state.sis_lista_valor.find(sis_lista_valor => sis_lista_valor.id_lista_valor === listId),
    valueLists: state => state.sis_lista_valor,
}
export const mutations = {
    setFormType(state, formType) {
        state.formType = formType

        switch(formType){
            case 'navigation':
                document.title = `${state.title}`;
                break;
            case 'creation':
                document.title = `Criação | ${state.title}`;
                break;
            case 'edition':
                document.title = `Edição | ${state.title}`;
                break;
            case 'show':
                document.title = `Visualização | ${state.title}`;
                break;
        }
    },
    setTitle(state, title) {
        state.title = title
    },
    updateOptionsValueList(state, sis_lista_valor){
        if(state.sis_lista_valor.find(slv => slv.id_lista_valor == sis_lista_valor.id_lista_valor)){
            const index = state.sis_lista_valor.findIndex(slv => slv.id_lista_valor == sis_lista_valor.id_lista_valor)
            state.sis_lista_valor[index] = sis_lista_valor
        }else{
            state.sis_lista_valor.push(sis_lista_valor)
        }
    },
}