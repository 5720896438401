import AzureHelper from "../helpers/azure";

const azureHelper = new AzureHelper()

function handleAppStartup() {
    azureHelper.uploadPendingDocuments()
    window.addEventListener('online', () => {
        if(!navigator.onLine) return
        console.log('Online novamente!')
        azureHelper.uploadPendingDocuments()
    })
}

export {
    handleAppStartup,
}