import store from "@/state/store";

export default [
  {
    path: "/",
    name: "default",
    meta: {
      title: "Home",
      authRequired: true,
    },
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/documentacao",
    name: "help",
    meta: {
      title: "Ajuda",
      authRequired: true,
    },
    component: () => import("../views/documentacao/index.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next){
        const storage = {...localStorage.valueOf()};
        localStorage.clear();
        sessionStorage.clear();
        if(storage.rememberedUser !== undefined){localStorage.setItem('rememberedUser', storage.rememberedUser)}
        if(storage.data_layout_mode !== undefined){localStorage.setItem('data_layout_mode', storage.data_layout_mode)}
        if(storage.companiesGlobalFilter !== undefined){localStorage.setItem('companiesGlobalFilter', storage.companiesGlobalFilter)}
        if(storage.config_program_table !== undefined){localStorage.setItem('config_program_table', storage.config_program_table)}
        next();
      },
    },
    component: () => import("../views/auth/logout/basic")
  },
  {
    path: '/login/email',
    name: 'signin-with-email-basic',
    meta: {
      title: "Sistema Apoio",
      beforeResolve(routeTo, routeFrom, next) {
        if (localStorage.getItem('user')) {
          next({ name: "default" });
        } else {
          next();
        }
      },
    },
    component: () => import('../views/auth/signin/with_email_basic')
  },
  {
    path: '/login',
    name: 'signin-basic',
    meta: {
      title: "Sistema Apoio",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
        } else {
          // Continue to the signin page
          next();
        }
      },
    },
    component: () => import('../views/auth/signin/basic')
  },
  {
    path: '/register',
    name: 'signup-basic',
    meta: {
      title: "Registro", authRequired: false,
    },
    
    component: () => import('../views/auth/signup/basic')
  },
  {
    path: '/lockscreen',
    name: 'lock-screen-basic',
    meta: {
      title: "Sistema Apoio", authRequired: true,
    },
    component: () => import('../views/auth/lockscreen/basic')
  },
  {
    path: '/auth/404',
    name: '404',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404')
  },
  {
    path: '/auth/500',
    name: '500',
    meta: {
      title: "Error 500", authRequired: true,
    },
    component: () => import('../views/auth/errors/500')
  },
  {
    path: '/auth/404-basic',
    name: '404-basic',
    meta: {
      title: "Error 404", authRequired: true,
    },
    component: () => import('../views/auth/errors/404-basic')
  },
  {
    path: '/offline',
    name: 'offline',
    meta: {
      title: "Sistema Apoio",
    },
    component: () => import('../views/offline/index.vue')
  },
  {
    params: true,
    path: "/:routeModule/:routeTree/graficos/:targetId",
    name: "dynamic-module-tree-panel",
    meta: {
      title: "",
      authRequired: true,
    },
    component: () => import("../views/painel/index.vue"),
  },
  {
    params: true,
    path: "/:routeModule/:routeTree/:routeProgram/:targetId",
    name: "dynamic-module-tree-program",
    meta: {
      title: "",
      authRequired: true,
    },
    component: () => import("../views/formularios/index.vue"),
  },
  {
    params: true,
    path: "/:targetId",
    name: "dynamic-home-program",
    meta: {
      title: "",
      authRequired: true,
    },
    component: () => import("../views/formularios/index.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "not-found",
    meta: {
      title: "Sistema Apoio",
      authRequired: false,
    },
    component: () => import("../views/not_found_404/index.vue"),
  },
];