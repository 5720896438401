import axios from "axios"
import { StorageDB } from '@/helpers/indexedDB.js'
import store from "@/state/store"
import { ApiService } from "@/helpers/api.service"
import Swal from "sweetalert2"
export default class AzureHelper {
    constructor(){
        this.document_store_name = 'documents'
        this.documentDB = new StorageDB({
            database_name: 'documentDB', 
            database_version: 1, 
            on_upgrade_needed: [
                { store_name: this.document_store_name, key_path: 'id_documento' }
            ],
        })
    }

    async confirmUpload(document){
        return await ApiService({
            method: 'POST',
            url: `/program`,
            data: {
                condicao_adicional: "",
                lista_id_empresa: JSON.parse(localStorage.getItem('companiesGlobalFilter')) ?? [],
                data_trabalho: JSON.parse(localStorage.getItem('dataTrabalho')),
                navegador: false,
                ordenacao: null,
                quantidade_registros: 0,
                pagina: 0,
                rota: 'confirma_upload_documento',
                dados: {
                    sis_documento: {
                        registros: [{ id_documento: document.id_documento }]
                    }
                }
            },
            headers: {'authorization': JSON.parse(localStorage.getItem('user')).token}
        })
        .then(async (res) => {
            if(!res.data.status){
                Swal.fire({
                    icon: "error",
                    text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                    confirmButtonColor: "#34c38f",  
                    confirmButtonText: "OK",
                });
            }else{
                await this.documentDB.delete(this.document_store_name, document.id_documento)
            }
        })
        .catch(error => {
            Swal.fire({
                icon: "error",
                text: error.response.data.mensagem ?? error.message,
                confirmButtonColor: "#34c38f",  
                confirmButtonText: "OK",
            });
        })
    }

    async uploadChuncks({
        resolve,
        reject,
        document,
        defaultChunkSize, /*MB*/
        chunkNumber,
        asyncJob
    }){
        const { file, link_upload } = document
        const chunkSize = defaultChunkSize * 1024 * 1024
        const totalChunks = Math.ceil(file.size / chunkSize)
        for (let i = chunkNumber; i < totalChunks; i++) {
            let startByte = i * chunkSize
            const endByte = Math.min(startByte + chunkSize, file.size)
            const chunk = file.slice(startByte, endByte)
            const headers = {
                "x-ms-version": "2020-04-08",
                "x-ms-blob-type": "AppendBlob",
                "Content-Type": "application/octet-stream",
                "x-ms-blob-condition-appendpos": startByte.toString(),
            }

            try {
                document.chunk_size = defaultChunkSize
                document.chunk_number = i
                this.documentDB.add_or_update(this.document_store_name, document)
                await axios.put(`${link_upload}&comp=appendblock`, chunk, { headers })
                
                const percentage = parseInt(((i + 1) / totalChunks) * 100)
                asyncJob.message = `Situação: ${percentage}%`
                store.commit('asyncJobs/updateJob', { ...asyncJob })
            } catch (error) {
                document.azure_upload_error = error.message
                document.chunk_number = i
                await this.documentDB.add_or_update(this.document_store_name, document)
                
                console.error(error)
                return reject(error)
            }
        }
        this.totalUploaded++
        resolve(document)
    }

    async uploadDocuments(documents){
        const tasks = documents.map(async(document) => {
            const asyncJob = {
                id: document.id_documento,
                title: `Upload de arquivo em andamento: ${document.nome_documento}`,
                message: 'Situação: 0%',
                status: 'running',
                show: true,
            }
            store.commit('asyncJobs/addJob', { ...asyncJob })

            return new Promise((resolve, reject) => this.uploadChuncks({
                resolve,
                reject,
                document,
                defaultChunkSize: document.chunk_size ?? 1,
                chunkNumber: document.chunk_number ?? 0,
                asyncJob
            }))
            .then(async () => {
                await this.confirmUpload(document)

                asyncJob.title = `Upload de arquivo concluído: ${document.nome_documento}`
                asyncJob.status = 'done'
                store.commit('asyncJobs/updateJob', { ...asyncJob })
            })
            .catch(async (error) => {
                asyncJob.title = `Erro ao fazer upload de arquivo: ${document.nome_documento}`
                asyncJob.status = 'failed'
                asyncJob.message = error.message
                store.commit('asyncJobs/updateJob', { ...asyncJob })
            })
        })
        await Promise.all(tasks)
        this.documentDB.close()
    }

    async uploadPendingDocuments(){
        const pendingDocuments = await this.documentDB.list(this.document_store_name)
        if(pendingDocuments.length){
            const tasks = pendingDocuments.forEach(async document => {
                const response = await axios.head(document.link_upload);
                const uploadedSize = response.headers['content-length'] || 0;
                const chunkSize = (document.chunk_size ?? 1) * 1024 * 1024;
                const chunkNumber = Math.floor(uploadedSize / chunkSize);
                if (uploadedSize >= document.file.size) {
                    this.documentDB.delete(this.document_store_name, document.id_documento);
                    return;
                }

                const asyncJob = {
                    id: document.id_documento,
                    title: `Upload de arquivo em andamento: ${document.nome_documento}`,
                    message: 'Situação: 0%',
                    status: 'running',
                    show: true,
                }
                store.commit('asyncJobs/addJob', { ...asyncJob })
                
                return new Promise((resolve, reject) => 
                    this.uploadChuncks({ 
                        resolve,
                        reject,
                        document,
                        defaultChunkSize: document.chunk_size ?? 1,
                        chunkNumber,
                        asyncJob
                    })
                )
                .then(async () => {
                    await this.documentDB.delete(this.document_store_name, document.id_documento);

                    asyncJob.title = `Upload de arquivo concluído: ${document.nome_documento}`
                    asyncJob.status = 'done'
                    store.commit('asyncJobs/updateJob', { ...asyncJob })
                })
                .catch(async (error) => {
                    asyncJob.title = `Erro ao fazer upload de arquivo: ${document.nome_documento}`
                    asyncJob.status = 'failed'
                    asyncJob.message = error.message
                    store.commit('asyncJobs/updateJob', { ...asyncJob })
                })
            })
            await Promise.all(tasks)
            this.documentDB.close()
        }
    }
}