import axios from "axios";
import Swal from "sweetalert2";
import vueJwtDecode from 'vue-jwt-decode'
import store from '@/state/store'

async function tryToLogin({loginData, req}) {
    return await axios.post(process.env.VUE_APP_API_URL + '/login', loginData)
    .then(function(response){
        const user = { token: response.data.token, refreshToken: response.data.refresh_token }
        localStorage.setItem("sis_config",JSON.stringify(response.data.sis_config))
        localStorage.setItem("user", JSON.stringify(user))
        req.headers.authorization = response.data.token
        return req
    })
    .catch(async (error) => {
        const res = error.response
        if(error.response.data.code == 'OTP_NOT_INFORMED'){
            const otpPromise = new Promise((resolve) => {  
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showTFAModal',
                        value: true
                    },
                    {
                        key: 'TFAloading',
                        value: false
                    },
                    {
                        key: 'TFAonSave',
                        value: async (otp_code) => {
                            store.commit('massActionsModals/setProperty', [{key: 'showTFAModal', value: false}])
                            loginData.otp = otp_code
                            resolve(loginData)
                        }
                    }
                ])
            });
            return otpPromise.then((otpData) => {
                return tryToLogin({loginData: otpData, req: req})
            })
        }
        if(error.response.data.code == 'INVALID_CREDENTIALS'){
            await Swal.fire({
                icon: "error",
                text: "Usuário ou senha inválidos.",
                confirmButtonColor: "#34c38f",
                confirmButtonText: 'Tentar Novamente',
                customClass: {
                    confirmButton: "w-100"
                }
            })
            return checkJwtToken(req)
        }
        if(error.response.data.code == 'INVALID_OTP'){
            await Swal.fire({
                icon: "error",
                text: "OTP inválido",
                confirmButtonColor: "#34c38f",
                confirmButtonText: 'Tentar Novamente',
                customClass: {
                    confirmButton: "w-100"
                }
            })
            loginData.otp = ""
            return tryToLogin({loginData: loginData, req: req})
        }
        if(error.response.data.code == 'INTERNAL_ERROR'){
            Swal.fire({
                icon: "error",
                text: "Erro interno, por favor tente novamente mais tarde.",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }
        if(error.response.status === 400){
            Swal.fire({
                icon: "error",
                text: res.data.mensagem ? res.data.mensagem : res.data.erro,
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
        }
    })
}

async function checkJwtToken(req) {
    if (localStorage.getItem('user')) {
        const { token, refreshToken} = JSON.parse(localStorage.getItem('user'));
        const decoded = vueJwtDecode.decode(token);
        const current_time = Date.now().valueOf();
        
        if (new Date((decoded.exp * 1000) -1*60000) < new Date(current_time)) {
            const passwordPromise = new Promise((resolve) => {  
                store.commit('massActionsModals/setProperty', [
                    {
                        key: 'showPasswordInputModal',
                        value: true
                    },
                    {
                        key: 'passwordInputModalLoading',
                        value: false
                    },
                    {
                        key: 'passwordInputModalonSave',
                        value: async (otp_code) => {
                            store.commit('massActionsModals/setProperty', [{key: 'showPasswordInputModal', value: false}])
                            resolve(otp_code)
                        }
                    }
                ])
            });

            return passwordPromise.then(async (password) => {
                return axios.post(process.env.VUE_APP_API_URL + '/refresh-token', {senha: password}, {headers: {authorization: refreshToken}})
                .then(function (response) {
                    const user = { token: response.data.token, refreshToken };
                    localStorage.setItem("user", JSON.stringify(user));
                    req.headers.authorization = response.data.token;
                    return req;
                })
                .catch(async function (error) {
                    if(error.response.status === 400){
                        Swal.fire({
                            icon: "error",
                            text: "Senha inválida, por favor tente novamente.",
                            confirmButtonColor: "#34c38f",
                            confirmButtonText: "OK",
                        });
                        return checkJwtToken(req)
                    }
                    if(error.response.status === 401){
                        const sis_config = JSON.parse(localStorage.getItem('sis_config'))
                        const loginData = {
                            email: decoded.email,
                            senha: password,
                            otp: "",
                            sistema: sis_config.sis_configuracao_sistema.id_configuracao_sistema,
                        }
                        return tryToLogin({loginData: loginData, req: req})
                    }
                    return req
                });
            })
        } else {
            return req;
        }
    }
    return req;
}

async function healthAndJwtInterceptors(req) {
    return await axios.get(process.env.VUE_APP_API_URL + '/health')
    .then(async function (response) {
        if (response.data.database) {
            if(req.headers.authorization && !req.url.includes('/refresh-token')){
                return checkJwtToken(req)
            }
            return req;
        }else{
            Swal.fire({
                icon: "error",
                text: "Estamos offline, por favor tente novamente mais tarde.",
                confirmButtonColor: "#34c38f",
                confirmButtonText: "OK",
            });
            return await healthAndJwtInterceptors(req)
        }
    })
    .catch(async function (error) {
        Swal.fire({
            icon: "error",
            text: error.response?.data?.message || "Estamos offline, por favor tente novamente mais tarde.",
            confirmButtonColor: "#34c38f",
            confirmButtonText: "OK",
        });
        if (navigator.onLine) {
            return await healthAndJwtInterceptors(req)
        }
    });
}

export {
    healthAndJwtInterceptors,
}