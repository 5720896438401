class StorageDB {
    constructor({database_name, database_version, on_upgrade_needed}){
        this.db = null
        this.database_name = database_name
        this.database_version = database_version
        this.on_upgrade_needed = on_upgrade_needed
    }

    async ready() {
        if (this.db) {
            return Promise.resolve(this.db)
        }
        return new Promise((resolve, reject) => {
            const request = indexedDB.open(this.database_name, this.database_version)
            request.onupgradeneeded = (event) => {
                const db = event.target.result
                this.on_upgrade_needed.forEach(({store_name, key_path}) => {
                    if (!db.objectStoreNames.contains(store_name)) {
                        db.createObjectStore(store_name, { keyPath: key_path });
                    }
                })
            }
            request.onsuccess = (event) => {
                this.db = event.target.result
                resolve(this.db)
            }
            request.onerror = (event) => {
                reject(event.target.error)
            }
        })
    }

    async add_or_update(store_name, object) {
        try {
            const db = await this.ready()
            return new Promise((resolve, reject) => {
                const transaction = db.transaction([store_name], 'readwrite')
                const objectStore = transaction.objectStore(store_name)
                const request = objectStore.put(object)
                request.onsuccess = () => {
                    resolve(request.result)
                }
                request.onerror = (event) => {
                    reject(event.target.error)
                }
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async get(store_name, id) {
        try {
            const db = await this.ready()
            return new Promise((resolve, reject) => {
                const transaction = db.transaction([store_name], 'readonly')
                const objectStore = transaction.objectStore(store_name)
                const request = objectStore.get(id)
                request.onsuccess = () => {
                    if (request.result) {
                        resolve(request.result)
                    } else {
                        resolve(null)
                    }
                }
                request.onerror = (event) => {
                    reject(event.target.error)
                }
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async list(store_name) {
        try {
            const db = await this.ready()
            return new Promise((resolve, reject) => {
                const transaction = db.transaction([store_name], 'readonly')
                const objectStore = transaction.objectStore(store_name)
                const request = objectStore.getAll()
                request.onsuccess = () => {
                    resolve(request.result)
                }
                request.onerror = (event) => {
                    reject(event.target.error)
                }
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    async delete(store_name, id) {
        try {
            const db = await this.ready()
            return new Promise((resolve, reject) => {
                const transaction = db.transaction([store_name], 'readwrite')
                const objectStore = transaction.objectStore(store_name)
                const request = objectStore.delete(id)
                request.onsuccess = () => {
                    resolve(request.result)
                }
                request.onerror = (event) => {
                    reject(event.target.error)
                }
            })
        } catch (error) {
            return Promise.reject(error)
        }
    }

    close() {
        if (this.db) {
            this.db.close();
            this.db = null;
        }
    }
}

export {
    StorageDB
}