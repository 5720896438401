<script>
import { onMounted } from 'vue';
import { handleAppStartup } from '@/config/index.js'

export default {
  name: 'App',
  components: {
  },
  setup() {
    onMounted(
      () => handleAppStartup()
    )
  }
}
</script>

<template>
  <router-view></router-view>
</template>