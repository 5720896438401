

export const state = {
    painels: [],
    refreshPBI: false,
    expandedBI: false
};

export const getters = {
    painels: state => state.painels,
    refreshPBI: state => state.refreshPBI,
    expandedBI: state => state.expandedBI,
};

export const mutations = {
    setPainels(state, newValue) {
        state.painels = newValue
    },
    setRefreshPBI(state) {
        state.refreshPBI = !state.refreshPBI;
    },
    setExpandedBI(state, newV) {
        state.expandedBI = newV;
    }
};

